var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Last Education",
      "placeholder": "Employee's last education",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.lastEducation,
      callback: function callback($$v) {
        _vm.lastEducation = $$v;
      },
      expression: "lastEducation"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Education Institution",
      "placeholder": "Employee's education institution name",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.instituteName,
      callback: function callback($$v) {
        _vm.instituteName = $$v;
      },
      expression: "instituteName"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }