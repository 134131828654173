<template>
  <div class="flex flex-col gap-6">
    <TextField type="text" label="Last Education" placeholder="Employee's last education" :borderEnabled="true" v-model="lastEducation" :disabled="props2 === 'Detail'" />
    <TextField type="text" label="Education Institution" placeholder="Employee's education institution name" :borderEnabled="true" v-model="instituteName" :disabled="props2 === 'Detail'" />
  </div>
</template>

<script>
export default {
  props: {
    props1: {
      type: Object,
      default: () => ({})
    },
    props2: {
      type: String
    },
    lastEducation() {
      return this.props1.lastEducation || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.lastEducation : 'Data unavailable'
    },
    instituteName() {
      return this.props1.instituteName || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.instituteName : 'Data unavailable'
    }
  },
  components: {
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField')
  }
}
</script>

<style></style>
